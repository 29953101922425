import { useGetQuery } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { FRIENDS_ENDPOINT } from "../constants";

export enum ShiftVisibilityEnum {
  PUBLIC = "PUBLIC",
  FRIENDS = "FRIENDS",
}

export const shiftVisibilitySchema = z.nativeEnum(ShiftVisibilityEnum);
export type ShiftVisibility = z.infer<typeof shiftVisibilitySchema>;

const workerFriendSettingsResponseSchema = z.object({
  data: z.object({
    type: z.literal("friend-settings"),
    attributes: z.object({
      shiftVisibility: shiftVisibilitySchema,
    }),
  }),
});

export type WorkerFriendSettingsResponse = z.infer<typeof workerFriendSettingsResponseSchema>;

export function useGetWorkerFriendSettings(): UseQueryResult<WorkerFriendSettingsResponse> {
  const worker = useDefinedWorker();
  return useGetQuery({
    url: `${FRIENDS_ENDPOINT(worker.userId)}/friend-settings`,
    responseSchema: workerFriendSettingsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKER_FRIEND_SETTINGS_FAILURE,
    },
  });
}
