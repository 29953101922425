import { BottomSheet, CbhIcon, LoadingSpinner } from "@clipboard-health/ui-components";
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { Button } from "../../../components/Button";
import { IconButton } from "../../../components/IconButton";
import { ProfileImage } from "../../components/ProfileImage";
import { useGetFriendsList } from "../../hooks/useGetFriendsList";
import { useGetOutboundFriendsRequests } from "../../hooks/useGetOutboundFriendsRequests";
import { useGetWorkerPublicProfile } from "../../hooks/useGetWorkerPublicProfile";
import { useSendFriendRequest } from "../../hooks/useSendFriendRequest";
import { type FriendIdParams } from "../../paths";
import WorkWithFriendsContext from "../../WorkWithFriendsContext";

interface Props {
  modalState: UseModalState;
  onClose: () => void;
}

export default function AddFriendBottomSheet(props: Props) {
  const { modalState, onClose } = props;

  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();
  const worker = useDefinedWorker();
  const { friendId = "" } = useParams<FriendIdParams>();
  const { data: profileData, isLoading: isProfileLoading } = useGetWorkerPublicProfile(friendId);
  const { data: friendsList, isLoading: isFriendsLoading } = useGetFriendsList();
  const { data: outboundRequests, isLoading: isOutboundLoading } = useGetOutboundFriendsRequests();
  const { setSelectedTab } = useContext(WorkWithFriendsContext);

  const { mutate: sendFriendRequest, isLoading: isSending } = useSendFriendRequest({
    onSuccess: () => {
      if (isDefined(profileData)) {
        showSuccessToast(
          `Friend request sent to ${profileData.attributes.firstName} ${profileData.attributes.lastName}`
        );
        void queryClient.invalidateQueries({ queryKey: ["outbound-friends-requests"] });
        setSelectedTab("pending");
        onClose();
      }
    },
  });

  const isAlreadyFriend = friendsList?.data.some((friend) => friend.id === friendId);

  const hasOutboundRequest = outboundRequests?.data.some((request) => request.id === friendId);

  const isLoading = isProfileLoading || isFriendsLoading || isOutboundLoading;

  if (isLoading) {
    return (
      <BottomSheet modalState={modalState}>
        <Stack flex={1} justifyContent="center" alignItems="center" sx={{ padding: 10 }}>
          <LoadingSpinner size="large" />
        </Stack>
      </BottomSheet>
    );
  }

  if (!isDefined(profileData)) {
    // Could not find worker
    onClose();
    return null;
  }

  return (
    <BottomSheet modalState={modalState}>
      <Stack flex={1} flexDirection="column">
        <Stack flex={1} alignItems="flex-end" p={4}>
          <IconButton variant="outlined" iconType="close" size="small" onClick={onClose} />
        </Stack>
        <Stack px={6} pb={10} alignItems="center">
          <Stack mt={10} mx={10} alignItems="center">
            <ProfileImage friend={profileData} size="large" />
            <Title
              component="h3"
              variant="h3"
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
              py={4}
              align="center"
            >
              Add friend
            </Title>
            <Title
              component="h6"
              variant="h6"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              align="center"
            >
              Add {`${profileData.attributes.firstName} ${profileData.attributes.lastName}`} as a
              friend so they know the shifts you are booking.
            </Title>
          </Stack>
          <Stack spacing={4} flex={1} width="100%" mt={10}>
            <Button
              variant="contained"
              disabled={
                isSending ||
                worker.userId === friendId ||
                isAlreadyFriend === true ||
                hasOutboundRequest === true
              }
              onClick={() => {
                sendFriendRequest(friendId);
              }}
            >
              <CbhIcon sx={{ mr: 2 }} type="invite" />
              {
                // eslint-disable-next-line no-nested-ternary
                isAlreadyFriend
                  ? "You're already friends!"
                  : hasOutboundRequest
                  ? "Friend request pending"
                  : "Send Friend Request"
              }
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </BottomSheet>
  );
}
