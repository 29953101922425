import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

export const workerPublicProfileResponseSchema = z.object({
  id: z.string(),
  type: z.literal("worker"),
  attributes: z.object({
    firstName: z.string(),
    lastName: z.string(),
    qualifications: z.array(z.string()),
    profileImageUrl: z.string().optional(),
  }),
});

export type WorkerPublicProfile = z.infer<typeof workerPublicProfileResponseSchema>;

export function useGetWorkerPublicProfile(workerId: string): UseQueryResult<WorkerPublicProfile> {
  return useQuery({
    queryKey: [`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/:userId/public`, workerId],
    queryFn: async () => {
      const result = await get({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/${workerId}/public`,
        responseSchema: workerPublicProfileResponseSchema,
      });
      return result.data;
    },
  });
}
