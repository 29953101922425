import { post } from "@src/appV2/api/api";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";

import { FRIENDS_ENDPOINT } from "../constants";
import { SendFriendRequestResponseSchema } from "./useSendFriendRequest";

export function useAcceptFriendRequest(
  options?: Omit<UseMutationOptions<void, unknown, string>, "mutationFn">
): UseMutationResult<void, unknown, string> {
  const worker = useDefinedWorker();
  return useMutation({
    mutationFn: async (workerId: string) => {
      await post({
        url: `${FRIENDS_ENDPOINT(worker.userId)}/friend-requests`,
        responseSchema: SendFriendRequestResponseSchema,
        data: {
          data: {
            type: "worker-friend-request",
            relationships: {
              targetWorker: {
                data: {
                  type: "worker",
                  id: workerId,
                },
              },
            },
          },
        },
      });
    },
    ...options,
  });
}
