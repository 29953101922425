import { remove } from "@src/appV2/api/api";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { z } from "zod";

import { FRIENDS_ENDPOINT } from "../constants";

export function useRemoveFriend(
  options?: Omit<UseMutationOptions<void, unknown, string>, "mutationFn">
): UseMutationResult<void, unknown, string> {
  const worker = useDefinedWorker();
  return useMutation({
    mutationFn: async (workerId: string) => {
      await remove({
        url: `${FRIENDS_ENDPOINT(worker.userId)}/friends`,
        queryParams: {
          workerId,
        },
        responseSchema: z.string(),
      });
    },
    ...options,
  });
}
