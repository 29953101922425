import { Stack } from "@mui/material";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import FriendsBlankState from "../components/BlankState";
import FriendRow from "../components/FriendRow";
import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";
import WorkWithFriendsContext from "../WorkWithFriendsContext";

interface Props {
  friends: WorkerPublicProfile[];
}
export default function TabFriends(props: Props) {
  const { friends } = props;

  const history = useHistory();
  const { setActiveFriend } = useContext(WorkWithFriendsContext);

  if (!friends?.length) {
    return <FriendsBlankState />;
  }

  return (
    <Stack spacing={6}>
      {friends.map((friend) => (
        <FriendRow
          key={friend.id}
          friend={friend}
          actions={
            <Stack>
              <IconButton
                iconType="ellipsis"
                variant="outlined"
                onClick={() => {
                  setActiveFriend(friend);
                  history.push(`/home-v2/account/friends/remove/${friend.id}`);
                }}
              />
            </Stack>
          }
        />
      ))}
    </Stack>
  );
}
