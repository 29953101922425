import { useGetQuery } from "@src/appV2/api";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { FRIENDS_ENDPOINT } from "../constants";

const inviteFriendLinkResponseSchema = z.object({
  data: z.object({
    type: z.literal("invite-friend-link"),
    attributes: z.object({
      url: z.string(),
    }),
  }),
});

export type InviteFriendLinkResponse = z.infer<typeof inviteFriendLinkResponseSchema>;

export function useGetInviteFriendLink(): UseQueryResult<InviteFriendLinkResponse> {
  const worker = useDefinedWorker();

  return useGetQuery({
    url: `${FRIENDS_ENDPOINT(worker.userId)}/invite-friend-link`,
    responseSchema: inviteFriendLinkResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_INVITE_FRIEND_LINK_FAILURE,
    },
  });
}
