import { get } from "@src/appV2/api/api";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { FRIENDS_ENDPOINT } from "../constants";
import { workerPublicProfileResponseSchema } from "./useGetWorkerPublicProfile";
import { useIsWorkWithFriendsV2Enabled } from "./useIsWorkWithFriendsV2Enabled";

const friendsListResponseSchema = z.object({
  data: z.array(workerPublicProfileResponseSchema),
});

export type FriendsListResponse = z.infer<typeof friendsListResponseSchema>;

export function useGetFriendsList(): UseQueryResult<FriendsListResponse> {
  const worker = useDefinedWorker();
  const isWorkWithFriendsV2Enabled = useIsWorkWithFriendsV2Enabled();

  return useQuery({
    queryKey: ["friends-list"],
    queryFn: async () => {
      const result = await get({
        url: `${FRIENDS_ENDPOINT(worker.userId)}/friends`,
        responseSchema: friendsListResponseSchema,
      });
      return result.data;
    },
    enabled: isWorkWithFriendsV2Enabled,
  });
}
