import { Illustration } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { useContext } from "react";

import { Button } from "../../../components/Button";
import WorkWithFriendsContext from "../../WorkWithFriendsContext";

interface ConfirmationProps {
  onConfirm: () => void;
  onCancel: () => void;
  pending: boolean;
}

export default function Confirmation(props: ConfirmationProps) {
  const { onConfirm, onCancel, pending } = props;

  const { activeFriend } = useContext(WorkWithFriendsContext);

  return (
    <Stack px={6} pb={10} alignItems="center">
      <Stack mt={10} mx={10} alignItems="center">
        <Illustration type="person-destructive" />
        <Title
          component="h2"
          variant="h1"
          sx={(theme) => ({
            color: theme.palette.text.primary,
          })}
          py={4}
          align="center"
        >
          Are you sure?
        </Title>
        <Stack display="inline">
          <Text display="inline" sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
            {`${activeFriend?.attributes.firstName} ${activeFriend?.attributes.lastName}`}&nbsp;
          </Text>
          <Text display="inline">
            will be removed from your friends list and you will no longer see each other&apos;s
            shifts during booking.
          </Text>
        </Stack>
      </Stack>
      <Stack spacing={4} flex={1} width="100%" mt={10}>
        <Button variant="destructive" disabled={pending} onClick={onConfirm}>
          Remove Friend
        </Button>
        <Button variant="outlined" disabled={pending} onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}
