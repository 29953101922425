import { BottomSheet, LoadingSpinner } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import { IconButton } from "../../../components/IconButton";
import {
  ShiftVisibilityEnum,
  useGetWorkerFriendSettings,
} from "../../hooks/useGetWorkerFriendSettings";
import { useSetShiftVisibility } from "../../hooks/useSetShiftVisibility";

const visibilityOptions = [
  {
    value: ShiftVisibilityEnum.PUBLIC,
    label: "Visible to everyone",
    description: "Anyone can see when you're working on the same shift",
  },
  {
    value: ShiftVisibilityEnum.FRIENDS,
    label: "Visible to friends only",
    description: "Only your friends can see when you're working on the same shift",
  },
] as const;

type VisibilityOption = (typeof visibilityOptions)[number]["value"];

interface Props {
  modalState: UseModalState;
  onClose: () => void;
}

export function VisibilitySettingsBottomSheet(props: Props) {
  const { modalState, onClose } = props;
  const [selectedVisibility, setSelectedVisibility] = useState<VisibilityOption>(
    ShiftVisibilityEnum.PUBLIC
  );

  const { data: friendSettings, isLoading: isLoadingFriendSettings } = useGetWorkerFriendSettings();

  useEffect(() => {
    if (friendSettings) {
      setSelectedVisibility(friendSettings.data.attributes.shiftVisibility);
    }
  }, [friendSettings]);

  const { mutate: setShiftVisibility } = useSetShiftVisibility();

  function handleVisibilityChange(event: React.ChangeEvent<HTMLInputElement>) {
    setShiftVisibility(event.target.value as VisibilityOption);
    setSelectedVisibility(event.target.value as VisibilityOption);
  }

  return (
    <BottomSheet modalState={modalState}>
      <Stack flex={1} flexDirection="column" p={6}>
        <Stack flex={1} alignItems="flex-end" p={4} mb={6}>
          <IconButton
            variant="outlined"
            iconType="close"
            size="small"
            onClick={() => {
              onClose();
            }}
          />
        </Stack>
        <Stack px={6} pb={10}>
          <Title component="h2" variant="h3" color="text.primary" pb={8}>
            Who can see you on shifts
          </Title>
          {isLoadingFriendSettings ? (
            <Stack flex={1} justifyContent="center" alignItems="center">
              <LoadingSpinner size="large" />
            </Stack>
          ) : (
            <RadioGroup
              value={selectedVisibility}
              onChange={async (event) => {
                handleVisibilityChange(event);
              }}
            >
              <Stack spacing={4}>
                {visibilityOptions.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={
                      <Stack ml={1}>
                        <Text variant="body1" color="text.primary" sx={{ fontWeight: "bold" }}>
                          {option.label}
                        </Text>
                        <Text variant="body2" color="text.secondary">
                          {option.description}
                        </Text>
                      </Stack>
                    }
                  />
                ))}
              </Stack>
            </RadioGroup>
          )}
        </Stack>
      </Stack>
    </BottomSheet>
  );
}
