import { get } from "@src/appV2/api/api";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { FRIENDS_ENDPOINT } from "../constants";
import { workerPublicProfileResponseSchema } from "./useGetWorkerPublicProfile";

const friendsListResponseSchema = z.object({
  data: z.array(workerPublicProfileResponseSchema),
});

export type FriendsListResponse = z.infer<typeof friendsListResponseSchema>;

export function useGetOutboundFriendsRequests(): UseQueryResult<FriendsListResponse> {
  const worker = useDefinedWorker();
  return useQuery({
    queryKey: ["outbound-friends-requests"],
    queryFn: async () => {
      const result = await get({
        url: `${FRIENDS_ENDPOINT(worker.userId)}/friend-requests`,
        queryParams: {
          direction: "outbound",
        },
        responseSchema: friendsListResponseSchema,
      });
      return result.data;
    },
  });
}
