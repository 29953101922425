import { Share } from "@capacitor/share";
import { BottomSheet, CbhIcon, Illustration } from "@clipboard-health/ui-components";
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import { isCapacitorPlatform } from "@src/appV2/lib/utils/capacitor/platformUtils";
import { USER_EVENTS } from "@src/constants";

import { Button } from "../../../components/Button";
import { IconButton } from "../../../components/IconButton";
import { useGetInviteFriendLink } from "../../hooks/useGetInviteFriendLink";

interface Props {
  modalState: UseModalState;
  onClose: () => void;
}

export default function WorkWithFriendsInviteBottomSheet(props: Props) {
  const { modalState, onClose } = props;

  const { showSuccessToast } = useToast();

  const { data: inviteFriendLinkData, isLoading: isInviteFriendLinkDataLoading } =
    useGetInviteFriendLink();

  return (
    <BottomSheet modalState={modalState}>
      <Stack flex={1} flexDirection="column">
        <Stack flex={1} alignItems="flex-end" p={4}>
          <IconButton variant="outlined" iconType="close" size="small" onClick={onClose} />
        </Stack>
        <Stack px={6} pb={10} alignItems="center">
          <Stack mt={10} mx={10} justifyContent="center" alignItems="center">
            <Illustration type="mail-person" />
            <Title
              component="h2"
              variant="h1"
              sx={(theme) => ({
                color: theme.palette.text.primary,
              })}
              py={4}
              align="center"
            >
              Add a Friend
            </Title>
            <Title
              component="h6"
              variant="h6"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
              align="center"
            >
              Connect with a friend so you can both see the shifts you book.
            </Title>
          </Stack>
          <Stack spacing={4} flex={1} width="100%" mt={10}>
            <Button
              variant="contained"
              disabled={
                isInviteFriendLinkDataLoading ||
                !isDefined(inviteFriendLinkData?.data.attributes.url)
              }
              onClick={async () => {
                if (!isDefined(inviteFriendLinkData?.data.attributes.url)) {
                  logError(USER_EVENTS.COPY_INVITE_FRIEND_LINK_FAILURE, {
                    error: "Invite friend link is not defined",
                    metadata: {
                      inviteFriendLinkData,
                    },
                  });
                  return;
                }

                if (isCapacitorPlatform()) {
                  await Share.share({
                    title: "Add Friends on Clipboard",
                    text: "Hey! Want to add me as a friend on Clipboard so we can see the shifts the other has booked and work together? Go here: ",
                    url: inviteFriendLinkData.data.attributes.url,
                  });
                } else {
                  await navigator.clipboard.writeText(inviteFriendLinkData.data.attributes.url);
                  showSuccessToast("Link copied to clipboard");
                }
              }}
            >
              <CbhIcon sx={{ mr: 2 }} type={isCapacitorPlatform() ? "arrow-up" : "copy"} />{" "}
              {isCapacitorPlatform() ? "Share Link" : "Copy Link"}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </BottomSheet>
  );
}
