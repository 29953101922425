import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { useQueryClient } from "@tanstack/react-query";

import { Button } from "../../components/Button";
import FriendsBlankState from "../components/BlankState";
import FriendRow from "../components/FriendRow";
import { useCancelOutboundFriendRequest } from "../hooks/useCancelFriendRequest";
import { type WorkerPublicProfile } from "../hooks/useGetWorkerPublicProfile";

interface Props {
  friends: WorkerPublicProfile[];
}
export default function TabPending(props: Props) {
  const { friends } = props;

  const { showSuccessToast } = useToast();
  const queryClient = useQueryClient();

  const { mutate: cancelOutboundFriendRequest } = useCancelOutboundFriendRequest({
    onSuccess: () => {
      showSuccessToast("Friend request canceled");
      void queryClient.invalidateQueries({ queryKey: ["outbound-friends-requests"] });
    },
  });

  if (!friends?.length) {
    return <FriendsBlankState />;
  }

  return (
    <Stack>
      {friends.map((friend) => (
        <FriendRow
          key={friend.id}
          friend={friend}
          actions={
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                cancelOutboundFriendRequest(friend.id);
              }}
            >
              Cancel
            </Button>
          }
        />
      ))}
    </Stack>
  );
}
