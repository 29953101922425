import { Stack } from "@mui/material";

import { Button } from "../../../components/Button";

interface ActionsProps {
  onRemove: () => void;
}

export default function Actions(props: ActionsProps) {
  const { onRemove } = props;

  return (
    <Stack spacing={4} flex={1} width="100%">
      <Button variant="destructive" onClick={onRemove}>
        Remove Friend
      </Button>
    </Stack>
  );
}
