import { patch } from "@src/appV2/api/api";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { z } from "zod";

import { FRIENDS_ENDPOINT } from "../constants";
import { type ShiftVisibility } from "./useGetWorkerFriendSettings";

export function useSetShiftVisibility(
  options?: Omit<UseMutationOptions<string, unknown, ShiftVisibility>, "mutationFn">
): UseMutationResult<string, unknown, ShiftVisibility> {
  const worker = useDefinedWorker();
  return useMutation({
    mutationFn: async (shiftVisibility: ShiftVisibility) => {
      const result = await patch({
        url: `${FRIENDS_ENDPOINT(worker.userId)}/friend-settings`,
        responseSchema: z.string(),
        data: {
          data: {
            type: "friend-settings",
            attributes: {
              shiftVisibility,
            },
          },
        },
      });
      return result.data;
    },
    ...options,
  });
}
